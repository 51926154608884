import * as React from "react"
import { Heading, Text, Button, Container } from '@chakra-ui/react'
import { Helmet } from "react-helmet"

// styles
const pageStyles = {
  color: "#232129",
  padding: "96px",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
}
const headingStyles = {
  fontSize: "3xl"
}

const paragraphStyles = {
  //marginBottom: 48,
}


// markup
const NotFoundPage = () => {
  return (
    <main style={pageStyles}>
      <Helmet>
        <title>Not Found</title>
      </Helmet>
      
      <Container>
        <Heading sx={headingStyles}>Whoops!</Heading>
        <Text style={paragraphStyles} paddingTop="10px">
          We couldn't find what you were looking for.
        <br />
 
        <br />
        <Button
            as="a"
            href="/"
            colorScheme="pink"
          >
            Return to the landing page
          </Button>
        </Text>
      </Container>
    </main>
  )
}

export default NotFoundPage
